// externals
import { useRef } from 'react';
import { useSelector } from 'react-redux';

// libraries
import { Alert } from '@interstate/components/Alert';
import { Typography } from '@interstate/components/Typography';

// store
import { changeVehicleSelectors } from '../../store';

// utils
import { useScrollIntoView } from '../../utils/useScrollIntoView';

// styles
import { ChangeVehicleHeaderContainer, ChangeVehicleHeaderContainerWrapper } from '../changeVehicle/ChangeVehicle.interstate.style';

export const ChangeVehicleHeaderInterstate = ({ enableConfirmModal = true }: { enableConfirmModal?: boolean }) => {
    const alertRef = useRef<HTMLDivElement>(null);
    const searchResults = useSelector(changeVehicleSelectors.getSearchResults);
    const isSearchError = useSelector(changeVehicleSelectors.getShowSearchError);
    const hasSearchResults = Boolean(searchResults?.length);
    const showProcessingError = useSelector(changeVehicleSelectors.getShowProcessingError);
    const showBuildVehicleError = useSelector(changeVehicleSelectors.getShowBuildVehicleError);
    const shouldScroll = showProcessingError || showBuildVehicleError;

    useScrollIntoView(alertRef, shouldScroll);

    return (
        <ChangeVehicleHeaderContainerWrapper>
            <ChangeVehicleHeaderContainer className="change-vehicle-header" data-testid="change-vehicle-header">
                <Typography variant="h1" className="change-vehicle-title" data-testid="changeVehicle" sx={{ marginBottom: '16px' }}>
                    Change Vehicle
                </Typography>

                <div ref={alertRef} className="change-vehicle-alert-container">
                    {!hasSearchResults && (
                        <Typography
                            variant="body-md"
                            tag="p"
                            sx={{ marginBottom: '16px' }}
                            data-testid="change-vehicle-alert-message"
                        >
                            {`You can either search for the vehicle using VIN or stock number or Build a vehicle to get an estimate of the vehicle.`}
                        </Typography>
                    )}
                    {showProcessingError && (
                        <Alert
                            id="change-vehicle-error"
                            type="error"
                            sx={{ marginBottom: '16px' }}
                            data-testid="change-vehicle-error"
                        >
                            <Typography variant="body-sm" tag="span">
                                We can't calculate the deal with the selected vehicle at the moment. Try again or choose another
                                vehicle.
                            </Typography>
                        </Alert>
                    )}
                    {showBuildVehicleError && (
                        <Alert type="error" sx={{ marginBottom: '16px' }} data-testid="build-vehicle-error">
                            <Typography variant="body-sm" tag="span">
                                Vehicle build request unsuccessful. Please retry or contact support if the issue persists.
                            </Typography>
                        </Alert>
                    )}

                    {hasSearchResults && !isSearchError && !showProcessingError && (
                        <Alert type="info" displayCloseButton sx={{ marginBottom: '16px' }} data-testid="change-vehicle-alert">
                            <Typography variant="body-sm" tag="span">
                                Entered values for finance type, term, down payment, trade-in vehicle, and value will persist. You
                                will need to reselect items like vehicle protection products, incentives and accessories.
                            </Typography>
                        </Alert>
                    )}
                </div>
            </ChangeVehicleHeaderContainer>
        </ChangeVehicleHeaderContainerWrapper>
    );
};
